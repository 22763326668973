 <!-- 跑马灯 -->
<template>
  <el-carousel  height="969px" motion-blur autoplay arrow="never">
    <el-carousel-item  ref="carouselItems">
      <img style="width: 100%;" src="@/assets/img/banner-1.png" alt="" />
    </el-carousel-item>
    <el-carousel-item  ref="carouselItems">
      <img style="width: 100%;" src="@/assets/img/banner-2.png" alt="" />
    </el-carousel-item>
    <el-carousel-item  ref="carouselItems">
      <img style="width: 100%;" src="@/assets/img/banner-3.png" alt="" />
    </el-carousel-item>
  </el-carousel>
 <!-- //鼠标箭头 -->
 <div class="mouseClass">
    <div class="VerticalLine"></div>
    <img src="@/assets/img/banner/12630.png" alt="">
   </div>
</template>

<script>
export default {
  name: "carousel",
 
};
</script>

<style lang="scss" scoped>



// 指示灯位置
:deep(.el-carousel__indicators) {
  display: flex;
  flex-direction: column;
  align-items: center;

  left: 3%;
  top: 50%;
  transform: unset;
  right: unset;
}
// 默认按钮的颜色大小等
:deep(.el-carousel__button) {
  height:  12px;
  width:  12px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  background: transparent;

}
// 选中图片的颜色
:deep(.is-active .el-carousel__button) {
  height:  12px;
  width:  12px;
  border: none;
  border-radius: 50%;
  background: #ffffff;
  opacity: 1;
}
.mouseClass{
  position: absolute; 
  right: 50px; /* 距离父元素左侧 40px */
  bottom: 67px;
  display: flex;
  flex-direction: column;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
}
.VerticalLine{
  width: 0px;
  height: 110px;
  border: 1px solid #D6D9DE;
  margin-bottom: 19px;
}




</style>