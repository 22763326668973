import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import './assets/svgIcon/index.js'

import SvgIcon from './assets/svgIcon/index.vue'// svg组件

window.__VUE_PROD_DEVTOOLS__ = false;
window.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = false;
router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
});
const app = createApp(App);
app.config.productionTip= false;
app.use(store);
app.use(router);
app.use(ElementPlus);
app.component('svg-icon', SvgIcon);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
//挂载app
app.mount('#app')
