<template>
  <div class="content">
    <img
      style="margin-top: 61px; margin-left: 358px; margin-bottom: 98px;"
      src="@/assets/img/partner/title.png"
      alt=""
    />

    <div class="image-carousel_1">
      <img   @mouseover="mouseover(1)" @mouseleave="mouseleave(1)"
        class="img"
        :style="{ transform: 'translateX(' + img.x + 'px)', zIndex: img.z }"
        v-for="(img, index) in dataOne"
        :key="index"
        :src="require('@/assets/img/partner/' + img.img)"
      />
    </div>
    <div class="image-carousel_2">
      <img @mouseover="mouseover(2)" @mouseleave="mouseleave(2)"
        class="img"
        :style="{ transform: 'translateX(' + img.x + 'px)', zIndex: img.z }"
        v-for="(img, index) in dataTwo"
        :key="index"
        :src="require('@/assets/img/partner/' + img.img)"
      />
    </div>
    <div class="image-carousel_1">
      <img @mouseover="mouseover(3)" @mouseleave="mouseleave(3)"
        class="img"
        :style="{ transform: 'translateX(' + img.x + 'px)', zIndex: img.z }"
        v-for="(img, index) in dataThree"
        :key="index"
        :src="require('@/assets/img/partner/' + img.img)"
      />
    </div>

  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";

export default {
  setup() {

     const dataOne = ref([{img:'huawei.png'},{img:'baiduyun.png'},{img:'dahua.png'},{img:'weishi.png'},
     {img:'huawei.png'},{img:'baiduyun.png'},{img:'dahua.png'},{img:'weishi.png'}]);

     const dataTwo = ref([{img:'gaode.png'},{img:'liantong.png'},{img:'yidong.png'},{img:'youren.png'},{img:'sixin.png'},
     {img:'gaode.png'},{img:'liantong.png'},{img:'yidong.png'},{img:'youren.png'},{img:'sixin.png'}]);
     const dataThree = ref([{img:'tiandy.png'},{img:'yangbang.png'},{img:'renke.png'},{img:'tianxing.png'},
     {img:'tiandy.png'},{img:'yangbang.png'},{img:'renke.png'},{img:'tianxing.png'}]);

    let data1intervalId;//第一个列表的定时任务id
    let data2intervalId;//第二个列表的定时任务id
    let data3intervalId;//第三个列表的定时任务id
    const scrollWidth = 20; // 每次移动20px
    const scrollInterval = 500; // 滚动间隔500毫秒
    let data1Length=8;//数组1的长度
    let data2Length=10;//数组2的长度
    let data3Length=8;//数组3的长度
    //创建原始数据
    const createData = () => {
      dataOne.value.forEach((item, index) => {
        item.x = index * 220;
        item.z = 1;
      });
      dataTwo.value.forEach((item, index) => {
        item.x = index * 220;
        item.z = 1;
      });
      dataThree.value.forEach((item, index) => {
        item.x = index * 220;
        item.z = 1;
      });
    };

    //鼠标移入(停止定时任务)
    const  mouseover =(item)=>{
      if(item==1){
        clearInterval(data1intervalId)
      }else if(item==2){
        clearInterval(data2intervalId)
      }else if(item==3){
        clearInterval(data3intervalId)
      }

    };
    //鼠标移出(开启定时任务)
    const  mouseleave=(item)=>{
      if(item==1){
        data1intervalId = setInterval(oneScroll, scrollInterval);
      }else if(item==2){
        data2intervalId = setInterval(twoScroll, scrollInterval);
      }else if(item==3){
        data3intervalId = setInterval(threeScroll, scrollInterval);
      }
    };

    const oneScroll = () => {
      //计算每张图片的位置
      dataOne.value.forEach((item, index, arr) => {
        if (item.x < -205) {
          arr[index].x = (data1Length-1)*220-scrollWidth;//用于计算滚动到左侧时，重置新的位置
          arr[index].z = -1;//更新位置时先把它隐藏起来
        } else {
          arr[index].x -= scrollWidth;
          arr[index].z = 1;
        }
      });
    };

    const twoScroll = () => {
      dataTwo.value.forEach((item, index, arr) => {
        if (item.x < -205) {
          arr[index].x = ( data2Length-1)*220-scrollWidth;//用于计算滚动到左侧时，重置新的位置
          arr[index].z = -1;//更新位置时先把它隐藏起来
        } else {
          arr[index].x -= scrollWidth;
          arr[index].z = 1;
        }
      });
    };

    const threeScroll = () => {
      dataThree.value.forEach((item, index, arr) => {
        if (item.x < -205) {
          arr[index].x = ( data3Length-1)*220-scrollWidth;//用于计算滚动到左侧时，重置新的位置
          arr[index].z = -1;//更新位置时先把它隐藏起来
        } else {
          arr[index].x -= scrollWidth;
          arr[index].z = 1;
        }
      });
    };

    onMounted(() => {
      //生成原始数据
      createData();
      //开启定时任务
      data1intervalId = setInterval(oneScroll, scrollInterval);
      data2intervalId = setInterval(twoScroll, scrollInterval);
      data3intervalId = setInterval(threeScroll, scrollInterval);
    });
    // 清理定时器
    onUnmounted(() => {
      clearInterval(data1intervalId)
      clearInterval(data2intervalId)
      clearInterval(data3intervalId)
    });
    return {
      dataOne,dataTwo,dataThree,mouseover,mouseleave
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  height: 641px;

  background-image: url("@/assets/img/partner/back.png");

}

.image-carousel_1 {
  overflow: hidden;
  width: 856px;
  height: 87px;
  margin: 0 auto;
  position: relative;

}


.image-carousel_2 {
  overflow: hidden;
  width: 1078px;
  height: 87px;
  margin: 32px auto;
  position: relative;
}


.img {
  width: 205px; /* 假设每个图片的宽度是200px */

  position: absolute;
  top: 0;
  left: 0; /* 根据需要调整位置 */
  transition: transform 0.5s linear;
}
</style>