<template>
  <div  class="bounce-in content">
    <div v-show="transitionShow" class="bounce-in header_img">
      <img
        style="margin-top: 160px; margin-bottom: 37px"
        src="@/assets/img/mycapacity/title.png"
        alt=""
      />
    </div>
    <div v-show="transitionShow" class="bounce-in main_class">
      <div class="teamListone" @mouseover="mouseover(1)" @mouseleave="mouseleave()" >
        <div>
          <div class="team_title">标准化生产车间</div>
          <div class="team_english" :style="{marginBottom: showIndex==1 ? '0px':'142px'}">Standardized production workshop</div>
          <div  v-show="showIndex==1" class="team_line"></div>
          <div v-show="showIndex==1" class="team_hover">高效交付，稳定生产</div>
          <div v-show="showIndex==1" class="team_hover_two">精细管理，规范作业</div>
          <img src="@/assets/img/mycapacity/factory.png" alt="" />
        </div>
      </div>
      <div class="teamListone"  @mouseover="mouseover(2)" @mouseleave="mouseleave()" >
        <div>
          <div class="team_title">专业的研发团队</div>
          <div class="team_english" :style="{marginBottom: showIndex==2 ? '0px':'142px'}">A professional R&D team</div>
          <div v-show="showIndex==2" class="team_line"></div>
          <div v-show="showIndex==2" class="team_hover">创新驱动，务实不倦</div>
          <div v-show="showIndex==2" class="team_hover_two">专业严谨，软硬兼备</div>
          <img src="@/assets/img/mycapacity/team.png" alt="" />
        </div>
      </div>
      <div class="teamListtwo"  @mouseover="mouseover(3)" @mouseleave="mouseleave()" >
        <div>
          <div class="team_title">完善的售后服务</div>
          <div class="team_english" :style="{marginBottom: showIndex==3 ? '0px':'142px'}">Comprehensive after-sales service</div>
          <div v-show="showIndex==3" class="team_line"></div>
          <div v-show="showIndex==3" class="team_hover">体系健全，快速响应</div>
          <div v-show="showIndex==3" class="team_hover_two">7*24小时以客户为中心</div>
          <img src="@/assets/img/mycapacity/sales.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {showIndex:0,
      transitionShow:false,
    }
  },
  props: {
    scrollTop: {
      type: Number,
      default:0,
    },
  },
  watch: {
    scrollTop(val){
      if (val < 3900) {
        this.transitionShow = false;
      }else if (val >= 4000 && val < 4900) {
        this.transitionShow = true;
      }
    }
  },
    //操作
    methods: {
    //鼠标放上去
    mouseover(index){
      //延迟1S执行
      if(index != this.showIndex){
        this.showIndex=index;
      }
    },
    //鼠标离开
    mouseleave(){
      this.showIndex=0;
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  height: 886px;
  background-image: url("@/assets/img/mycapacity/back.png");
}
.header_img {
  display: flex;
  justify-content: center; /* 水平居中 */
}
.main_class {
  display: flex;
  justify-content: center; /* 水平居中 */
}
.teamListone {
  width: 480px;
  height: 514px;
  border-right: 2px solid rgba(183, 207, 255, 0.47);
  display: flex;
  flex-direction: column;
  align-items: center; /* 水平居左 */
  justify-content: center; /* 垂直居中 */
}

.teamListtwo {
  width: 480px;
  height: 514px;
  display: flex;
  flex-direction: column;
  align-items: center; /* 水平居左 */
  justify-content: center; /* 垂直居中 */
}
.team_title {
  font-weight: 400;
  font-size: 26px;
  color: #ffffff;
  line-height: 39px;
}
.team_english {
  font-weight: 400;
  font-size: 14px;
  color: #b1b5be;
  line-height: 21px;

}
.team_line {
  margin-top: 9px;
}
.team_hover {
  width: 320px;
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
  line-height: 39px;
  border-top: 1px solid #AAB4C0;
  padding-top: 33px;
}
.team_hover_two{

  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
  line-height: 39px;
  margin-bottom: 102px;
}

// .teamListtwo :hover,
// .teamListone :hover {

//   .team_english{
//     margin-bottom: 0px;
//   }

// }
</style>