<template>
  <div class="container">
    
    <div v-show="transitionShow" class=" bounce-in header_img">
      <img style="margin-top: 105px;width: 368px;" src="@/assets/img/cloud/title.png" alt=""/>
    </div>
    <!-- //中间部分（分三部分：一级为左右 2级为左侧上下） -->
    <div v-show="transitionShow" class="bounce-in middle">
      <div style="width: 795px;">
        <div class="middle_title">
          通过洲鹏云平台，实现多设备间的协同与资源共享，设备联网、设备发现、建立连接、数据投送。将各设备的信息资源集中统一的安全平台，实现信息的安全传输。
        </div>
        <div class="middle_line">
          <div style="height: 10px;"></div>
          <el-row :gutter="20" type="flex" justify="center" >
            <el-col  v-for="(item, index) in middle_data"    :key="index"   :span="7">
              <div class="middle_item">
                <img  :src="require('@/assets/img/cloud/' + item.img)" alt="" />
                <div style="margin-left: 19px;" >
                  <span  style="  display: block;  font-weight: 500;  font-size: 20px; line-height: 20px;  color: #333333; margin-bottom: 8px;">{{ item.title1 }}</span>
                  <span style=" display: block; font-weight: 400; font-size: 16px; line-height: 20px;  color: #707070;">{{ item.title2 }}</span>
              </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div style="width: 405px">
        <img  src="@/assets/img/cloud/right_img.png" alt="" />
      </div>
    </div>

    <!-- 底部文字说明 -->
    <div v-show="transitionShow" class="bounce-in" style="width: 1200px;margin: 0 auto 25px auto;">
      <img style="vertical-align: middle; " src="@/assets/img/cloud/inlet.png" alt="" />
      <span class="inlet_title" >提供多端用户入口</span
      >
    </div>
    <!-- //底部图片 -->
    <div v-show="transitionShow" class="bounce-in foot_desc">
      <div v-for="(item, index) in foot_data" :key="index" :class="item.class">
        <img :src="require('@/assets/img/cloud/' + item.img)" alt="" />
        <span class="foot_title">{{ item.title }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      transitionShow:false,//缓慢展示
      foot_data: [
        { class: "pcclass desc", img: "pc.png", title: "WEB网站" },
        { class: "mobileclass desc", img: "mobile.png", title: "移动端APP" },
        { class: "wxclass desc", img: "wx.png", title: "微信小程序" },
        { class: "deviceclass desc", img: "device.png", title: "智能终端" },
      ],
      middle_data: [
        { img: "iot.png", title1: "物联设备", title2: "智能设备融合通信" },
        { img: "transmission.png", title1: "安全传输",  title2: "信息资源集中统一",},
        { img: "efficiency.png", title1: "稳定高效", title2: "稳定高效的共享协同",},
        { img: "collect.png", title1: "数据采集", title2: "提前采集实时数据" },
        { img: "alarm.png", title1: "智能预警", title2: "数据实时警示推送" },
        { img: "cloud.png", title1: "云端服务", title2: "完整的开发生态架构" },
      ],
    };
  },
  props: {
    scrollTop: {
      type: Number,
      default:0,
    },
  },
  watch: {
    scrollTop(val){
      if (val < 1900) {
        this.transitionShow = false;
      }else if (val >= 2000 && val < 3100) {
        this.transitionShow = true;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.container {

  height: 1000px;
  background-image: url("@/assets/img/cloud/back.png");
}
.header_img {
  display: flex;
  justify-content: center; /* 水平居中 */
}

.middle {
  width: 1200px;
  margin: 60px auto;

  display: flex;
  justify-content: space-between; /* 水平居中 */
}
.middle_title {
  width:729px;
  margin-top: 15px;
  font-weight: 500;
  font-size: 18px;
  color: #333333;
  list-style: 25px;
  line-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.middle_line {
  margin-top: 35px;
  height: 270px;
  background: linear-gradient(
    90deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0.8) 54%,
    rgba(255, 255, 255, 0.2) 100%
  );
  

}
.middle_item {
  width: 201px;
  height: 44px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 55px;

}
.foot_desc {
  margin: 0 auto 148px auto;
  width: 1200px;
  display: flex;
  justify-content: space-between; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}
.desc {
  width: 285px;
  height: 130px;
  border-radius: 5px;
  display: flex;
  justify-content: space-evenly;
  align-items: center; /* 垂直居中 */
}
.pcclass {
  background: linear-gradient(270deg, #07c9ff 0%, #2696f8 100%);
}
.mobileclass {
  background: linear-gradient(271deg, #9db6f5 0%, #7c75ff 100%);
}
.wxclass {
  background: linear-gradient(90deg, #0bd98a 0%, #2ce8ac 100%);
}
.deviceclass {
  background: linear-gradient(90deg, #486180 0%, #5e7da5 100%);
}
.foot_title {
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
  line-height: 18px;
}
.inlet_title{
  vertical-align: middle;
  font-weight: bold;
          font-size: 24px;
          color: #333333;
          line-height: 18px;
          margin-left: 15px;
}
</style>