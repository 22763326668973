<template>
  <!-- 跑马灯部分 -->
  <one ></one>
  <two :scrollTop="section2Top"></two>
  <three ></three>
  <four :scrollTop="section2Top"></four>
  <five :scrollTop="section2Top"> </five>
  <six :scrollTop="section2Top"></six>
  <seven ></seven>
</template>

<script>
import one from "./compontent/one.vue";
import two from "./compontent/two.vue";
import three from "./compontent/three.vue";
import four from "./compontent/four.vue";
import five from "./compontent/five.vue";
import six from "./compontent/six.vue";
import seven from "./compontent/seven.vue";

export default {
  name: "home",
  components: { one, two, three, four, five, six, seven },
  data() {
    return {
      section2Top: 0,
   
    };
  },
  //检测滚动到的位置
  mounted() {
    window.addEventListener("scroll", this.checkScrollPosition);
  },
  unmounted() {
    window.removeEventListener("scroll", this.checkScrollPosition);
  },
  methods: {
    checkScrollPosition() {
      this.section2Top =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>