<template>
  <div class="content">
    <!-- 先写最底层的（这个是多层叠加的） -->
    <div v-show="transitionShow" class="bounce-in" style="margin-left: 267px; margin-top: 125px; width: 693px;">
      <img
        src="@/assets/img/home_hardware/hardware.png"
        alt=""
      />
      <div style="margin-top: 82px; font-size: 36px; color: #222222">
        {{ explain_head[explain_num] }}
      </div>
      <div style="margin-top: 60px; font-size: 18px">
        <span style="margin-right: 30px; color: #45cfe5">{{
          explain[explain_num][0]
        }}</span>
        <span style="margin-right: 30px; color: #2e9eff">{{
          explain[explain_num][1]
        }}</span>
        <span style="margin-right: 30px; color: #8481ff">{{
          explain[explain_num][2]
        }}</span>
        <span style="color: #ffab2a">{{ explain[explain_num][3] }}</span>
      </div>
      <div class="describe">
        {{ explain_describe[explain_num] }}
      </div>
      <div class="switchMain">
        <div class="switchClass">
          <div
            class="switchArrow"
            style="margin-right: 20px"
            @click="pageChange('left')"
          >
            <el-icon :size="20" color="#9EC8FF"><ArrowLeft /></el-icon>
          </div>
          <div class="switchArrow" @click="pageChange('right')">
            <el-icon :size="20" color="#9EC8FF"><ArrowRight /></el-icon>
          </div>
        </div>
        <div class="switchRight" @click="Jump()">
          <span style="padding-right: 16px">查看更多</span>
          <svg-icon icon-class="switch" className="swap-right" />
        </div>
      </div>
    </div>
    <!-- 智慧预警硬件右边 -->
    <div v-show="transitionShow" class="bounce-in two_right" >
      <div style="position: absolute;  margin-top: 88px" :style="{marginLeft: explain_img_left[explain_num]}">
        <img   :src="require('@/assets/img/home_hardware/' +explain_img[explain_num])"  alt=""/>
      </div>

      <div class="tagEndDescribe">
        <div>
          <span :class="explain_num == 0 ? 'dot_red' : 'dot_blue'"></span
          ><span> {{ explain_head[0] }}</span>
        </div>
        <div>
          <span :class="explain_num == 1 ? 'dot_red' : 'dot_blue'"></span
          ><span> {{ explain_head[1] }}</span>
        </div>
        <div>
          <span :class="explain_num == 2 ? 'dot_red' : 'dot_blue'"></span
          ><span> {{ explain_head[2] }}</span>
        </div>
      </div>

      <div class="tagEndImg">
        <img :style="explain_image_size[explain_num]" :src="explain_image[explain_num]" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "two",
  props: {
    scrollTop: {
      type: Number,
      default:0,
    },
  },
  watch: {
    scrollTop(val){
      if (val < 100) {
        this.transitionShow = false;
      }else if (val >= 100 && val < 1100) {
        this.transitionShow = true;
      }
    }
  },


  data() {
    return {
      transitionShow:false,
      explain_num: 0,
      explain_head: ["道路会车预警终端", "LED抓拍测速仪", "数码管抓拍测速仪"],
      explain_img:['01.png','02.png','03.png'],
      explain_img_left:['76px','373px','645px'],
      explain: [
        ["超速预警", "视频监控", "远程信息发布", "车辆识别检测"],
        ["测速抓拍", "车速识别", "超速文字提醒", "雷达检测"],
        ["测速抓拍", "车速识别", "超速文字提醒", "雷达检测"],
      ],
      explain_describe: [
        "将设备部署在平交路口，通过雷达实时监测主路来车、支路行人和非机动车。当雷达检测到主路来车时，对支路行人以及非机动车发出声光预警，屏幕文字显示“左侧来车”等字样，提醒行人注意主路来车减速慢行。主要应用在弯道、十字路口、国省道平交路口及无灯控路口。",
        "通过高精度毫米波雷达对过往车辆进行实时测速，对未超速车辆进行绿色文字预警，超速车辆进行红色文字预警提示。通过摄像机抓拍过车车辆照片，摄像机会将抓拍的图片自动存储，并可通过屏幕进行显示提醒。在联网情况下，可同步回传至中心后台。主要应用在园区、高速、隧道口、高架等限速路段。",
        "通过高精度毫米波雷达对过往车辆进行实时测速，对未超速车辆进行绿色文字预警，超速车辆进行红色文字预警提示。通过摄像机抓拍过车车辆照片，摄像机会将抓拍的图片自动存储，并可通过屏幕进行显示提醒。在联网情况下，可同步回传至中心后台。主要应用在园区、高速、隧道口、高架等限速路段。",
      ],
      explain_image: [
        require("@/assets/img/home_hardware/shaobing.png"),
        require("@/assets/img/home_hardware/zhupai.png"),
        require("@/assets/img/home_hardware/shuma.png"),
      ],
      explain_image_size:[
        { marginLeft: '40%', marginTop: '27%'},
        { marginLeft: '38%', marginTop: '29%'},
        {marginLeft: '38%', marginTop: '29%'},
      ]

    };
  },
  methods: {
    pageChange(item) {
      if (item == "left") {
        if (this.explain_num <= 0) {
          this.explain_num = 2;
        } else {
          this.explain_num -= 1;
        }
      } else {
        if (this.explain_num >= 2) {
          this.explain_num = 0;
        } else {
          this.explain_num += 1;
        }
      }
    },
    //页面跳转
    Jump() {
        //跳转到关于洲鹏页面
        this.$router.push('/product/hardware?param=1');
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
height: 1022px;
  width:100%;
  background: #ffffff;
  display: flex;
}

.describe {
  margin-top: 122px;
  width: 576px;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #707070;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.switchMain {
  margin-top: 122px;
  width: 576px;
  display: flex;
  justify-content: space-between;

}
.switchClass {
  display: flex;

}
.switchArrow {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  border: 2px solid #9ec8ff;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}
.switchRight {
  width: 196px;
  height: 50px;
  border-radius: 25px;
  border: 2px solid #1269ca;
  display: flex;
  font-size: 16px;
  color: #1269ca;
  line-height: 30px;
  justify-content: center;
  align-items: center;
}
.switchArrow:hover {
  border: 2px solid #1269ca;
}

//divy颜色变更时连带着下面的一起变化
.switchArrow:hover .el-icon {
  color: #1269ca;
}
.swap-right {
  color: #1269ca;
}
.switchRight:hover {
  background: #1269ca;
  color: #ffffff;
}
.switchRight:hover .swap-right {
  color: #ffffff;
}
.two_right{

  background-image:url('@/assets/img/home_hardware/236.png') ;

}
.tagEndDescribe {
  width: 772px;
  height: 28px;
  position: relative;
  top: 143px;
  left:71px;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 22px;
  color: #222222;
  line-height: 28px;
}
.dot_red {
  background-color: #fd4952;
  border-radius: 50%;
  display: inline-block;
  vertical-align: top;
  margin-top: 10px;
  box-shadow: 0 0 0 6px #ffc8cd;
  width: 10px;
  height: 10px;
  margin-right: 15px;
}
.dot_blue {
  background-color: #2268f3;
  border-radius: 50%;
  display: inline-block;
  vertical-align: top;
  margin-top: 10px;
  box-shadow: 0 0 0 6px #b4cce8;
  width: 10px;
  height: 10px;
  margin-right: 15px;
}
.tagEndImg {
  width: 960px;
  height: 100%;

}
</style>