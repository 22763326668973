import { createWebHashHistory, createRouter } from 'vue-router'
import home from '../views/home'
//路由
const routes = [
	{
		name: "home",
		path: "/",
		component: () => import(/* webpackChunkName: "layout" */ '@/layout'),
		children: [{
			path: '/',
			name: 'home',
			component: home
		}, {
			path: '/product/hardware',
			name: '/product/hardware',
			meta: {
				title: "道路会车预警终端"
			},
			component: () => import(/* webpackChunkName: "login" */ '@/views/product/hardware'),
		}, {
			path: "/product/hardware",
			name: '/product/hardware',
			component: () => import(/* webpackChunkName: "login" */ '@/views/product/hardware'),
			meta: {
				title: "LED抓拍测速仪"
			}
		}, {
			path: "/product/hardware",
			name: '/product/hardware',
			component: () => import(/* webpackChunkName: "login" */ '@/views/product/hardware'),
			meta: {
				title: "数码管"
			}
		},
		{
			path: "/product/hardware",
			name: '/product/hardware',
			component: () => import(/* webpackChunkName: "login" */ '@/views/product/hardware'),
			meta: {
				title: "阵列诱导屏"
			}
		},
		{
			path: "/product/hardware",
			name: '/product/hardware',
			component: () => import(/* webpackChunkName: "login" */ '@/views/product/hardware/'),
			meta: {
				title: "雾灯"
			}
		},
		{
			path: "/product/hardware",
			name: '/product/hardware',
			component: () => import(/* webpackChunkName: "login" */ '@/views/product/hardware'),
			meta: {
				title: "防火"
			}
		},
		{
			path: "/product/aiot",
			name: '/product/aiot',
			component: () => import(/* webpackChunkName: "login" */ '@/views/product/aiot'),
			meta: {
				title: "智能硬件"
			}
		},

		{
			path: "/programme",
			name: '/programme',
			component: () => import(/* webpackChunkName: "login" */ '@/views/programme/index.vue'),
			meta: {
				title: "解决方案"
			}
		},
		{
			path: "/about",
			name: '/about',
			component: () => import(/* webpackChunkName: "login" */ '@/views/about'),
			meta: {
				title: "关于洲鹏"
			}
		},
		{
			path: "/project",
			name: '/project',
			component: () => import(/* webpackChunkName: "login" */ '@/views/project'),
			meta: {
				title: "项目案例"
			}
		},

		{
			path: "/contact",
			name: '/contact',
			component: () => import(/* webpackChunkName: "login" */ '@/views/contact'),
			meta: {
				title: "联系我们"
			}
		},
		{
			path: "https://yj.hnzhoupeng.com:8889/",
			name: '/platform',
			meta: {
				title: "洲鹏云平台"
			}
		},
		]
	},
]

export default createRouter({
	history: createWebHashHistory(),
	routes,
})