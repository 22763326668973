<template>
  <div class="bounce-in container">
    <div  v-show="transitionShow" class="bounce-in switchJump" @click="Jump()">
      <span style="padding-right: 16px">查看更多</span>
      <svg-icon icon-class="switch" className="swap-right" />
    </div>

    <div v-show="transitionShow" class="bounce-in  left_class">
      <div style="width:528px; margin-left: 264px;">
        <img
          style="display: block; margin-bottom: 60px;"
          src="@/assets/img/company/title.png"
          alt=""
        />
        <img
          style="display: block; margin-bottom: 53px;"
          src="@/assets/img/company/start.png"
          alt=""
        />
        <span class="left_class_desc">
          河南省洲鹏智能科技有限公司坐落于华夏文明发祥地，“天地之中”的郑州。是一家集研发、生产、销售为一体的创新型物联网公司。公司专注于交通、水利领域行业场景化软硬件产品的研发。
          公司成立至今，以郑州为中心，业务辐射河南、河北、山东、山西、江苏、湖南、四川、广西、浙江、江苏、内蒙、新疆等省份及海外非洲地区的业务。
        </span>
        <span class="left_class_desc_two">
          公司坚持“客户第一”的原则，以技术为核心竞争力，为客户提供先进的物联网产品系统解决方案和智慧硬件设备的研发。</span
        >
        <img
          style="float: right; margin-top: 53px;"
          src="@/assets/img/company/end.png"
          alt=""
        />
      </div>
    </div>
    <div v-show="transitionShow" class="bounce-in  right_class">
      <img  src="@/assets/img/company/right_img.png" alt="" />
    </div>
  </div>
</template>

<script>
//公司简介部分
export default {

  props: {
    scrollTop: {
      type: Number,
      default:0,
    },
  },
  watch: {
    scrollTop(val){
      if (val < 3000) {
        this.transitionShow = false;
      }else if (val >= 3100 && val < 4000) {
        this.transitionShow = true;
      }
    }
  },
  data() {
    return {
      transitionShow:false,//缓慢展示
    };
  },

  methods: {
    Jump() {
      //跳转到关于洲鹏页面
      this.$router.push('/about');
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 1920px;
  height: 965px;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center;
}
//左边样式
.left_class {
  width: 960px;
}
//右边图片
.right_class {
  width: 960px;
}
.switchJump {
  position: absolute;
  z-index: 2;
  margin-top: 10%;
  margin-left: 10%;
  transform: translate(-50%, -50%);
  width: 196px;
  height: 50px;
  background: #1269ca;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  line-height: 50px;
}
.left_class_desc {
  display: block;
  font-weight: 400;
  font-size: 18px;
  color: #707070;
  text-indent: 2em;
  line-height: 30px;

}
.left_class_desc_two {
  display: block;
  font-weight: 400;
  font-size: 18px;
  color: #707070;
  text-indent: 2em;
  line-height: 30px;
}
</style>