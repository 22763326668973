<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <!-- 入口（意思是按路由文件走） -->

  <router-view></router-view>
</template>

<script>


export default {
  name: 'App',
  components: {

  }
}
</script>

<style scoped>

.router-view {
  overflow: auto; /* 使得当内容超出容器时，出现滚动条 */
  height: 100vh; /* 设置高度为视口高度，根据需要调整 */
  width: 100%; /* 设置宽度为100% */
}
</style>
