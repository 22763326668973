<template>
  <div   class="container">
        <!-- 行业解决方案标题 -->
    <img  class="main_title" src="@/assets/img/programme/title.png" alt="">
    <div class="boxList">
      <div v-for="(item,index) in items" :key="index" :class="item.class" @mouseover="mouseover(index+1)" @mouseleave="mouseleave()" >
        <img class="heard_img"  :src="require('@/assets/img/programme/'+item.hear_img)" alt="" />
        <span class="main_text" >{{item.main_text}}</span>
        <span  class="english_text" >{{item.english_text}}</span>
        <div  class="describe_line" ></div>
        <div class="describe" >{{item.describe}}</div>
        <img class="footer_img" :src="require('@/assets/img/programme/'+item.foot_img)" alt="" />
      </div>
    </div>
  </div>
  
</template>

<script>
export default {
  data(){
    return {
      mouse_index:0,
      items:[{hear_img:'min_one.png',main_text:'智慧交通',english_text:'Smart transportation',foot_img:'arrow_jump.png',class:'box box1',describe:'国省道平交路口，多为无灯控路口，交通状况复杂，支路多为乡村道路，车辆驾驶人及行人交通安全意识差，是极大的交通安全隐患，对这些道路进行安全预警可以有效的减少交通事故，保障安全出行。'},
      {hear_img:'min_two.png',main_text:'智慧水利',english_text:'Smart water conservancy',foot_img:'arrow_jump.png',class:'box box2',describe:'城市道路及国省道在经过隧道、涵洞、低洼的路段，雨季的时候经常会产生大量的积水，如果不能及时排除，会造成道路中断，甚至是车辆淹水，造成财产损失或人员伤亡。智慧水利通过对公共区域、水域领域实现自动监测、水质分析、水位预警，提升水资源的利用效率和水旱灾害的防御能力。'},
      {hear_img:'min_three.png',main_text:'智慧景区',english_text:'Smart Scenic Area',foot_img:'arrow_jump.png',class:'box box3',describe:'近年来，科技手段持续赋能旅游行业，通过引入5G、VR、AI技术，强化游客游览体验，创新景区内容。通过物联网、大数据、云计算等技术手段，构建景区“智慧大脑”。全面构建视频监控、应急广播、SOS报警终端、无线调度、宣传引导屏等硬件设备，深入推进智慧票务、智慧停车、一体化智能管理等服务系统应用。'},
      {hear_img:'min_four.png',main_text:'智慧园区',english_text:'Smart Park',foot_img:'arrow_jump.png',class:'box box4',describe:'智慧园区通过集成物联网、大数据、云计算、人工智能等技术，将园区内的基础设施、信息系统、社会服务和商业活动等连接起来，形成一个有机的整体，实现园区的数字化、网络化和智能化。实现对园区内设施、设备、人员等的全面感知和智能管理。为园区内的企业提供高效、便捷、智能的服务，从而提升园区的竞争力和可持续性。'},
      {hear_img:'min_five.png',main_text:'智慧社区',english_text:'Smart Community',foot_img:'arrow_jump.png',class:'box box5',describe:'智慧社区通过物联网、云计算、大数据等现代信息技术手段，对社区内人流、车流、能耗、安全事件等各项运营数据进行收集、整理和分析，并以可视化的形式展现，管理者可以更加准确地掌握社区的运行状态，更好地进行资源配置、安全防范、环境监控等工作。 提升社区管理和服务水平的新型社区形态。'}
      ],
    };
  },
  //操作
  methods: {
    //鼠标放上去
    mouseover(index){
      //延迟1S执行
      this.mouse_index=index;

    },
    //鼠标离开
    mouseleave(){
      this.mouse_index=0;
    }
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 1920px;
  height: 960px;

}
.boxList{
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}
.box {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* 水平居左 */
  justify-content: center; /* 垂直居中 */
  flex: 1;
  transition:  1s;
  .heard_img{
    transition: 1s;
     margin: 0 auto 50px auto;
    
  }
  .main_text{
    font-weight: 500;
    font-size: 20px;
    color: #ffffff;
    line-height: 28px;
    transition: 1s;
    margin: 0 auto ;
  }
  .english_text{
 
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 28px;
    transition: 1s;
    position:relative;
    transform:translate(-50%);
    left:50%;
  }
  .footer_img{
    transition: 1s;
    padding-bottom: 1px;
    margin: 0 auto ;
    background-size: 100% 100%;
  }
  .describe_line{
    opacity: 0;
    width: 70%; 

    border-top: 1px solid #FFFFFF; 
    margin: 16px  auto 34px 80px;

  }
  .describe{
    opacity: 0;
    width: 70%;
    height: 144px;
    font-weight: 400;
font-size: 16px;
color: #F2F2F2;
line-height: 30px;

    margin: 0 auto 58px 80px;
  }
}
.box:hover {
  flex: 2;
  transition: 1s;
  .heard_img{
    transform:translate(-210px);
  }
  .main_text{
    transform:translate(-200px);
  }
  .english_text{
    transform: translateX(0);
    left:12.5%;

    // margin-left: 80px;
  }
  .footer_img{
    transform:translate(-210px);
  }
  .describe,.describe_line{
    opacity: 1;
  }


}



.box1 {
  background-image: url("../../../assets/img/programme/one.png");
}
.box2 {
  background-image: url("../../../assets/img/programme/two.png");
}
.box3 {
  background-image: url("../../../assets/img/programme/three.png");
}
.box4 {
  background-image: url("../../../assets/img/programme/four.png");
}
.box5 {
  background-image: url("../../../assets/img/programme/five.png");
}

.main_title{
  position: absolute;
  
  z-index: 2;
  margin-left:50%;
  margin-top:87px;
  width: 207px;
  transform: translate(-50%, -50%);
  // top:0
}
</style>